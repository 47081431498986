import type { ReactNode } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import type { Theme } from '@mui/material';
import { Button, Tooltip, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
export enum OutcomeGroup {
  NONE = 'NONE',
  COULD_BE_CANCELLED = 'COULD_BE_CANCELLED',
  COULD_NOT_BE_CANCELLED = 'COULD_NOT_BE_CANCELLED',
  OFFER_COULD_BE_APPLIED = 'OFFER_COULD_BE_APPLIED',
  OFFER_COULD_NOT_BE_APPLIED = 'OFFER_COULD_NOT_BE_APPLIED',
}

const StyledGroupButton = styled(Button)`
  margin: 0;
  display: block;
  padding: 8px 22px 8px 22px;
  text-transform: none;
`;

const StyledContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

const StyledIconAndTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckIcon = styled(CheckIcon)`
  margin-right: 8px;
`;
const StyledHelpIcon = styled(HelpOutlineIcon)`
  margin-left: 16px;
`;
interface ComponentProps {
  children: ReactNode;
  currentActiveValue: OutcomeGroup;
  selfValue: OutcomeGroup;

  onGroupClicked(value: OutcomeGroup): void;
}

export const OutcomeGroupComponent = ({ children, selfValue, currentActiveValue, onGroupClicked }: ComponentProps) => {
  const isActive = selfValue === currentActiveValue;
  const theme = useTheme();
  const white = '#ffffff';
  return (
    <StyledContainer>
      <StyledGroupButton
        theme={theme}
        data-testid={`outcome-group-${selfValue}`}
        variant="contained"
        sx={{
          textAlign: 'left',
          backgroundColor: isActive ? theme.palette.primary.main : white,
          color: isActive ? white : 'inherit',
          '&:hover': {
            background: isActive ? theme.palette.primary.main : theme.palette.action.hover,
          },
        }}
        onClick={() => onGroupClicked(selfValue)}
        fullWidth
      >
        <StyledIconAndTextContainer>
          {isActive && <StyledCheckIcon />}
          <span>
            {(() => {
              switch (selfValue) {
                case OutcomeGroup.COULD_BE_CANCELLED:
                  return 'has been cancelled';
                case OutcomeGroup.COULD_NOT_BE_CANCELLED:
                  return 'could not be cancelled';
                case OutcomeGroup.OFFER_COULD_BE_APPLIED:
                  return 'is updated with an offer';
                case OutcomeGroup.OFFER_COULD_NOT_BE_APPLIED:
                  return 'could not be updated with an offer';
                default:
                  return null;
              }
            })()}
          </span>
          <Tooltip
            title={(() => {
              switch (selfValue) {
                case OutcomeGroup.COULD_BE_CANCELLED:
                  return 'Select if you can confirm the cancellation';
                case OutcomeGroup.COULD_NOT_BE_CANCELLED:
                  return 'Select if you are unable to confirm the cancellation';
                case OutcomeGroup.OFFER_COULD_BE_APPLIED:
                  return 'Select if you can confirm the offer';
                case OutcomeGroup.OFFER_COULD_NOT_BE_APPLIED:
                  return 'Select if you are unable to confirm the offer';
                default:
                  return null;
              }
            })()}
          >
            <StyledHelpIcon fontSize="small" />
          </Tooltip>
        </StyledIconAndTextContainer>
      </StyledGroupButton>
      {selfValue === currentActiveValue && <div>{children}</div>}
    </StyledContainer>
  );
};
