import styled from '@emotion/styled';
import { AutoAwesomeOutlined, FeedbackOutlined } from '@mui/icons-material';
import { Link } from '@mui/material';
import React from 'react';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 8px;
  justify-content: space-between;
`;
const LeftLearnMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const RightFeedbackLink = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <LeftLearnMoreContainer>
        <AutoAwesomeOutlined /> &nbsp;Tired of cancelling via Portal? Automate the process with Minna.&nbsp;
        <Link href="https://minnatechnologies.com/contact" target="_blank" rel="noopener noreferrer">
          Learn More
        </Link>
      </LeftLearnMoreContainer>
      <RightFeedbackLink
        href="mailto:support@minatjanster.zendesk.com"
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        <FeedbackOutlined />
        &nbsp;Give feedback
      </RightFeedbackLink>
    </FooterContainer>
  );
};
