const capitalizeFirstLetter = (string: string) => {
  if (string == null || string.trim() === '') {
    return string;
  }
  if (string.charAt(0).match(/[a-z]/i)) {
    return string
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  return string;
};

export function stringifyAnswer(answer: any): string {
  switch (answer.questionId) {
    case 'que_fullname':
      return `${capitalizeFirstLetter(answer.answer.firstName)} ${capitalizeFirstLetter(answer.answer.lastName)}`;
    case 'que_merchantemail' || 'que_contactemail':
      return `${(answer.answer as string).toLowerCase()}`;
    case 'que_offer':
      return answer.questionId ? 'Yes' : 'No';
    case 'que_deliveryaddress':
    case 'que_useraddress':
      return [
        answer.answer.line1,
        answer.answer.line2,
        answer.answer.postalCode,
        answer.answer.city,
        answer.answer.state,
        answer.answer.country,
      ]
        .filter((a) => a !== undefined)
        .join('\n');
    case 'que_mostrecenttransaction':
      return `${answer.answer.amount} ${answer.answer.currency} ${answer.answer.date} ${answer.answer.transactionIdentifier} ${answer.answer.transactionReferenceDetails}`;
    default:
      return answer.answer;
  }
}
