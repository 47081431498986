import styled from '@emotion/styled';
import { ContentCopy } from '@mui/icons-material';
import { IconButton, type Theme, Typography, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useLocalizedCostWithCurrency } from '../../utils/localization-helper';
import type { QuestionId } from '@minna-technologies/core-types/tech/minna/core/api/integration/actions/v3/models';
import type { TransactionAnswerPayload } from '@minna-technologies/core-types/tech/minna/core/api/integration/actions/v3/models/Answer';

const StyledTypography = styled(Typography)`
  white-space: pre-wrap;
  align-items: left;
`;

const AnswerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(IconButton)`
  min-width: 0;
  color: ${({ theme }: { theme: Theme }) => theme.palette.action.disabled};
`;

interface TransactionAnswer {
  questionId: QuestionId;
  answer: TransactionAnswerPayload;
}

interface TransactionAnswerProps {
  answer: TransactionAnswer;
  isAlternateQuestion: boolean;
  localise: (key: string) => string;
}

interface TransactionAnswerFieldsProps {
  answerObject: TransactionAnswerPayload;
  allowCopy: boolean;
}
interface TransactionAnswerPropertyToRender {
  label: string;
  value: string;
}

export const TransactionAnswerFields = ({ answerObject, allowCopy }: TransactionAnswerFieldsProps) => {
  const localise = useTranslations('TicketDetails');
  const theme = useTheme();

  const answerProperties = [
    {
      label: localise('amount'),
      value: useLocalizedCostWithCurrency(answerObject.amount, answerObject.currency),
    },
    {
      label: localise('date'),
      value: answerObject.date,
    },
    {
      label: localise('merchant'),
      value: answerObject.transactionIdentifier,
    },
    {
      label: localise('reference'),
      value: answerObject.transactionReferenceDetails,
    },
  ];

  const propertiesToRender: TransactionAnswerPropertyToRender[] = answerProperties
    .filter((property) => !!property.value)
    .map((property) => ({ label: property.label, value: property.value }));

  return (
    <AnswerDiv>
      {propertiesToRender.map(({ label, value }) => (
        <StyledTypography key={label} variant="body1">
          {`${label}: ${value}`}
          {allowCopy && (
            <StyledButton size="small" theme={theme} onClick={async () => navigator.clipboard.writeText(value)}>
              <ContentCopy fontSize="small" />
            </StyledButton>
          )}
        </StyledTypography>
      ))}
    </AnswerDiv>
  );
};

export const TransactionAnswer = ({ answer, isAlternateQuestion, localise }: TransactionAnswerProps) => {
  const questionLabel = isAlternateQuestion
    ? localise('alternate_label') + localise(answer.questionId)
    : localise(answer.questionId);
  return (
    <StyledDiv>
      <Typography variant="body1">{questionLabel}:&nbsp;</Typography>
      <TransactionAnswerFields answerObject={answer.answer} allowCopy />
    </StyledDiv>
  );
};
