"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThirdPartyPaymentMethod = exports.ActionPortalTicketResultType = void 0;
var ActionPortalTicketResultType;
(function (ActionPortalTicketResultType) {
    ActionPortalTicketResultType["ActionPortalTicketResultFixedContract"] = "ActionPortalTicketResultFixedContract";
    ActionPortalTicketResultType["ActionPortalTicketResultPayingViaThirdParty"] = "ActionPortalTicketResultPayingViaThirdParty";
    ActionPortalTicketResultType["ActionPortalTicketResultUserDetailsNotMatching"] = "ActionPortalTicketResultUserDetailsNotMatching";
    ActionPortalTicketResultType["ActionPortalTicketResultOfferApplied"] = "ActionPortalTicketResultOfferApplied";
    ActionPortalTicketResultType["ActionPortalTicketResultCancelled"] = "ActionPortalTicketResultCancelled";
    ActionPortalTicketResultType["ActionPortalTicketResultFreeText"] = "ActionPortalTicketResultFreeText";
    ActionPortalTicketResultType["ActionPortalTicketResultFreeAccount"] = "ActionPortalTicketResultFreeAccount";
    ActionPortalTicketResultType["ActionPortalTicketResultUserNotEligible"] = "ActionPortalTicketResultUserNotEligible";
    ActionPortalTicketResultType["ActionPortalTicketResultUserNotFound"] = "ActionPortalTicketResultUserNotFound";
    ActionPortalTicketResultType["ActionPortalTicketResultMultipleAccounts"] = "ActionPortalTicketResultMultipleAccounts";
})(ActionPortalTicketResultType || (exports.ActionPortalTicketResultType = ActionPortalTicketResultType = {}));
var ThirdPartyPaymentMethod;
(function (ThirdPartyPaymentMethod) {
    ThirdPartyPaymentMethod["OTHER"] = "OTHER";
    ThirdPartyPaymentMethod["ROKU"] = "ROKU";
    ThirdPartyPaymentMethod["APPLE"] = "APPLE";
    ThirdPartyPaymentMethod["PAYPAL"] = "PAYPAL";
    ThirdPartyPaymentMethod["GOOGLE"] = "GOOGLE";
})(ThirdPartyPaymentMethod || (exports.ThirdPartyPaymentMethod = ThirdPartyPaymentMethod = {}));
