import styled from '@emotion/styled';
import { ContentCopy } from '@mui/icons-material';
import { IconButton, type Theme, Typography, useTheme } from '@mui/material';
import React from 'react';
import { stringifyAnswer } from '../../utils/stringify-answer';

const StyledTypography = styled(Typography)`
  white-space: pre-wrap;
  align-items: left;
`;

const AnswerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(IconButton)`
  min-width: 0;
  color: ${({ theme }: { theme: Theme }) => theme.palette.action.disabled};
`;

interface AddressAnswerProps {
  answer: any;
  isAlternateQuestion: boolean;
  localise: (key: string) => string;
}

export const AddressAnswer = ({ answer, isAlternateQuestion, localise }: AddressAnswerProps) => {
  const answerString = stringifyAnswer(answer);
  const theme = useTheme();
  const questionLabel = isAlternateQuestion
    ? localise('alternate_label') + localise(answer.questionId)
    : localise(answer.questionId);

  return (
    <StyledDiv>
      <Typography variant="body1">{questionLabel}:&nbsp;</Typography>
      <AnswerDiv>
        {answerString.split('\n').map((addressField: any, index) => {
          return (
            <StyledTypography key={`${index}-${addressField}`} variant="body1">
              {addressField}
              {addressField && index === 0 && (
                <StyledButton
                  size="small"
                  theme={theme}
                  onClick={async () => navigator.clipboard.writeText(answerString)}
                >
                  <ContentCopy fontSize="small" />
                </StyledButton>
              )}
            </StyledTypography>
          );
        })}
      </AnswerDiv>
    </StyledDiv>
  );
};
