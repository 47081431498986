import styled from '@emotion/styled';
import { ContentCopy } from '@mui/icons-material';
import { IconButton, type Theme, Typography, useTheme } from '@mui/material';
import React from 'react';
import { stringifyAnswer } from '../../utils/stringify-answer';

const StyledTypography = styled(Typography)`
  white-space: pre-wrap;
  align-items: left;
`;

const StyledButton = styled(IconButton)`
  min-width: 0;
  color: ${({ theme }: { theme: Theme }) => theme.palette.action.disabled};
`;

interface DefaultAnswerProps {
  answer: any;
  isAlternateQuestion: boolean;
  localise: (key: string) => string;
}

export const DefaultAnswer = ({ answer, isAlternateQuestion, localise }: DefaultAnswerProps) => {
  const answerString = stringifyAnswer(answer);
  const theme = useTheme();
  const questionLabel = isAlternateQuestion
    ? localise('alternate_label') + localise(answer.questionId)
    : localise(answer.questionId);

  return (
    <>
      <Typography variant="body1">{questionLabel}:&nbsp;</Typography>
      <StyledTypography variant="body1">{answerString}</StyledTypography>
      {answerString && (
        <StyledButton size="small" theme={theme} onClick={async () => navigator.clipboard.writeText(answerString)}>
          <ContentCopy fontSize="small" />
        </StyledButton>
      )}
    </>
  );
};
