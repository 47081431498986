import React, { forwardRef, useImperativeHandle } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type {
  ActionPortalTicketResult,
  ActionPortalTicketResultType,
} from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { FormContainer } from './CommonStyles';
import { FormTextField } from './FormTextField';

export interface ActionPortalTicketFormData extends ActionPortalTicketResult {
  offerValidFrom: string;
  offerValidUntil: string;
  upcomingPaymentDate?: string;
  messageToUser?: string;
  type: ActionPortalTicketResultType.ActionPortalTicketResultOfferApplied;
}

interface TicketFormOfferAppliedProps {
  resultType: ActionPortalTicketResultType;
  onSubmitCallback: (resultContent: ActionPortalTicketResult) => void;
  ref?: React.Ref<unknown>;
}

export const TicketFormOfferApplied: React.FC<TicketFormOfferAppliedProps> = forwardRef(
  ({ resultType, onSubmitCallback }, ref) => {
    const {
      control,
      register,
      formState: { errors },
      handleSubmit,
      // Needed, else we get a red warning on 'register' props
      // eslint-disable-next-line
    } = useForm<FieldValues>({ shouldUnregister: true });

    const onSubmit = (data: ActionPortalTicketFormData) => {
      onSubmitCallback({
        ...data,
        offerValidFrom: data.offerValidFrom ? new Date(data.offerValidFrom).toISOString() : null,
        offerValidUntil: data.offerValidUntil ? new Date(data.offerValidUntil).toISOString() : null,
        upcomingPaymentDate: data.upcomingPaymentDate ? new Date(data.upcomingPaymentDate).toISOString() : null,
        type: resultType,
      } as ActionPortalTicketResult);
    };

    // Expose the onSubmit method through the ref
    useImperativeHandle(ref, () => handleSubmit(onSubmit));

    return (
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTextField
            control={control}
            errors={errors}
            register={register}
            propName="offerValidFrom"
            propLabel="Retention offer applied on"
            fieldType="date"
            isRequired
            data-testid={'ticket-form-offer-valid-from'}
          />

          <FormTextField
            control={control}
            errors={errors}
            register={register}
            propName="offerValidUntil"
            propLabel="Retention offer valid until"
            fieldType="date"
            data-testid={'ticket-form-offer-valid-until'}
          />

          <FormTextField
            control={control}
            errors={errors}
            register={register}
            propName="upcomingPaymentDate"
            propLabel="Next upcoming payment date"
            fieldType="date"
            data-testid={'ticket-form-upcoming-payment-date'}
            isRequired
          />

          <input type="submit" hidden />
        </form>
      </FormContainer>
    );
  }
);

TicketFormOfferApplied.displayName = 'TicketFormOfferApplied';
