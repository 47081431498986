import { TicketActionType, useTickets, useTicketsDispatch } from './TicketsProvider';
import { TicketCard } from './TicketCard';
import styled from '@emotion/styled';
import { TicketListHeader } from './TicketListHeader';
import { useMemo, useState } from 'react';
import type { Theme } from '@mui/material';
import { Typography, useTheme } from '@mui/material';
import { Button } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { getUserEmailFromAnswers, getUserNameFromAnswers } from './utils/answer-helper';

interface TicketListProps {
  numOpenedTickets: number;
  onLoadMore(): void;
}

const Scrollable = styled.div`
  position: relative;
  overflow-y: scroll;
  flex-grow: 1;
  overflow-x: hidden;
  height: calc(100vh - 300px);
`;

const ListContainer = styled.div<{ theme: Theme }>`
  width: 300px;
  min-height: calc(100vh - 78px); //view height minus header
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
`;

const StyledSpan = styled.span<{ theme: Theme }>`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const OpenTicketsStyledDiv = styled.div<{ theme: Theme }>`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: 8px 0;
`;

export const TicketList = ({ numOpenedTickets, onLoadMore }: TicketListProps) => {
  const ticketsContext = useTickets();
  const ticketsDispatch = useTicketsDispatch();
  const search = ticketsContext.search ? ticketsContext.search : '';
  const [sortKey, setSortKey] = useState('age');
  const [sortDirection, setSortDirection] = useState<number>(-1);
  const theme = useTheme();
  const updateSort = (newSortKey: string) => {
    const newSortDirect = sortKey === newSortKey ? -sortDirection : sortKey === 'user' ? -1 : 1;
    setSortKey(newSortKey);
    setSortDirection(newSortDirect);
  };

  const updateSearch = (newSearch: string) => {
    ticketsDispatch({
      type: TicketActionType.UPDATE_SEARCH,
      search: newSearch,
    });
  };

  const filteredAndSortedTickets = useMemo(() => {
    const filteredTickets = ticketsContext.tickets.filter((ticket) => {
      return (
        getUserEmailFromAnswers(ticket.actionDetails.answers).toLowerCase().includes(search.toLowerCase()) ||
        getUserNameFromAnswers(ticket.actionDetails.answers).toLowerCase().includes(search.toLowerCase()) ||
        ticketsContext.selectedTicket?.ticketId === ticket.ticketId
      );
    });

    if (sortKey === 'user') {
      filteredTickets.sort((t1, t2) => {
        return (
          getUserEmailFromAnswers(t1.actionDetails.answers).localeCompare(
            getUserEmailFromAnswers(t2.actionDetails.answers)
          ) * sortDirection
        );
      });
    } else {
      filteredTickets.sort((t1, t2) => {
        const age1 = t1.age.days * 24 + t1.age.hours;
        const age2 = t2.age.days * 24 + t2.age.hours;
        return (age1 - age2) * sortDirection;
      });
    }

    return filteredTickets;
  }, [search, sortKey, sortDirection, ticketsContext]);

  const openTicketsIndicator = (
    <OpenTicketsStyledDiv theme={theme}>
      <Typography data-testid="open-tickets-indicator">
        OPEN TICKETS: <StyledSpan theme={theme}>{numOpenedTickets}</StyledSpan>
      </Typography>
    </OpenTicketsStyledDiv>
  );

  return (
    <ListContainer theme={theme}>
      {openTicketsIndicator}
      <TicketListHeader
        sortKey={sortKey}
        sortDirection={sortDirection}
        updateSort={updateSort}
        search={ticketsContext.search}
        updateSearch={updateSearch}
      />
      <Scrollable>
        {filteredAndSortedTickets.map((ticket) => {
          return (
            <TicketCard
              key={ticket.ticketId}
              onClick={() => ticketsDispatch({ type: TicketActionType.SELECT_TICKET, ticketId: ticket.ticketId })}
              ticket={ticket}
              selected={ticket.ticketId === ticketsContext.selectedTicket?.ticketId}
            />
          );
        })}
      </Scrollable>
      <Button
        variant="text"
        color="primary"
        fullWidth
        endIcon={<ArrowDownward />}
        sx={{ textTransform: 'none' }}
        onClick={() => onLoadMore()}
      >
        Load More
      </Button>
    </ListContainer>
  );
};
