'use client';
import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import type { ActionPortalTicketOverview } from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { useSearchParams } from 'next/navigation';
import { InternalServerError } from './ErrorComponents/InternalServerError';
import { TicketExpired } from './ErrorComponents/TicketExpired';
import { Footer } from './Footer';
import { TicketList } from './TicketList';
import { TicketActionType, TicketsProvider, useTicketsDispatch } from './TicketsProvider';
import { listTickets } from '@minna-technologies/core-action-portal/ActionPortalTicketsApi';
import { Responses, TicketView } from './TicketView';
import { IconButton, Snackbar } from '@mui/material';
import { CloseIcon } from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -6px;
  height: 100%;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  justify-content: space-between;
  height: 100%;
  padding: 24px;
  flex: 1;
`;

const TicketsPageComponentInner = () => {
  const searchParams = useSearchParams();
  //get token from search params or from url fragment
  const token = searchParams?.get('token') || typeof window !== 'undefined' ? window.location.hash.substring(1) : '';

  const [resultState, setResultState] = useState(Responses.TICKET_VIEW);

  const [numOpenedTickets, setNumOpenedTickets] = useState<number>(0);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const ticketsDispatch = useTicketsDispatch();

  const fetchTickets = useCallback(() => {
    listTickets({ token: token })
      .then((overview: ActionPortalTicketOverview) => {
        setNumOpenedTickets(overview.ticketMetrics.open);

        ticketsDispatch({
          type: TicketActionType.REFRESH,
          tickets: overview.tickets,
        });

        ticketsDispatch({
          type: TicketActionType.SELECT_TICKET,
          ticketId: overview.tickets[0].ticketId,
        });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setResultState(Responses.UNAUTHORIZED);
        } else {
          setResultState(Responses.INTERNAL_SERVER_ERROR);
        }
      });
  }, [ticketsDispatch, token]);

  const onSubmit = (resultState: Responses) => {
    if (resultState === Responses.SUCCESS) {
      setOpenSnackBar(true);
    }
  };

  React.useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  const ticketContent = () => {
    switch (resultState) {
      case Responses.INTERNAL_SERVER_ERROR:
        return (
          <ErrorContainer>
            <InternalServerError />
            <Footer />
          </ErrorContainer>
        );
      case Responses.UNAUTHORIZED:
        return (
          <ErrorContainer>
            <TicketExpired />
            <Footer />
          </ErrorContainer>
        );
      default:
        return (
          <>
            <TicketList numOpenedTickets={numOpenedTickets} onLoadMore={fetchTickets} />
            <TicketView token={token} onSubmit={onSubmit} />
            <Snackbar
              open={openSnackBar}
              autoHideDuration={3000}
              onClose={() => {
                setOpenSnackBar(false);
              }}
              message={
                resultState === Responses.BAD_REQUEST
                  ? 'Ticket could not be closed because of an invalid input'
                  : 'Ticket closed successfully'
              }
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    setOpenSnackBar(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
          </>
        );
    }
  };

  useEffect(() => {
    const maybeTicket = searchParams.get('tck');
    if (maybeTicket) {
      ticketsDispatch({
        type: TicketActionType.SELECT_TICKET,
        ticketId: maybeTicket,
      });
    }
  }, [searchParams]);

  return <ContainerStyled>{ticketContent()}</ContainerStyled>;
};

export const TicketsPageComponent = () => {
  const searchParams = useSearchParams();
  const token = searchParams.get('token') || typeof window !== 'undefined' ? window.location.hash.substring(1) : '';

  const initialState = {
    token: token,
    tickets: [],
  };

  return (
    <TicketsProvider initialState={initialState}>
      <TicketsPageComponentInner />
    </TicketsProvider>
  );
};
