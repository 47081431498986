import React, { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import type {
  ActionPortalTicketResult,
  ActionPortalTicketResultFreeText,
  ActionPortalTicketResultType,
} from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { FormContainer } from './CommonStyles';
import { FormTextField } from './FormTextField';

interface TicketFormFreeTextProps {
  resultType: ActionPortalTicketResultType;
  onSubmitCallback: (resultContent: ActionPortalTicketResult) => void;
  ref?: React.Ref<unknown>;
}

export const TicketFormFreeText: React.FC<TicketFormFreeTextProps> = forwardRef(
  ({ resultType, onSubmitCallback }, ref) => {
    const {
      register,
      control,
      formState: { errors },
      handleSubmit,
    } = useForm();
    const onSubmit = (data: ActionPortalTicketResultFreeText) => {
      onSubmitCallback({ ...data, type: resultType } as ActionPortalTicketResult);
    };

    // Expose the onSubmit method through the ref
    useImperativeHandle(ref, () => handleSubmit(onSubmit));

    return (
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTextField
            control={control}
            errors={errors}
            register={register}
            propName="message"
            propLabel="Other reason"
            isRequired
          />
          <input type="submit" hidden />
        </form>
      </FormContainer>
    );
  }
);

TicketFormFreeText.displayName = 'TicketFormFreeText';
