import styled from '@emotion/styled';
import type { ActionPortalTicket } from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import type { Theme } from '@mui/material';
import { Card, Typography, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { AddressAnswer } from './AnswerComponents/AddressAnswer';
import { DefaultAnswer } from './AnswerComponents/DefaultAnswer';
import { TransactionAnswer } from './AnswerComponents/TransactionAnswer';
import TicketDetailsAgencyAgreement from './TicketDetailsAgencyAgreement';
import { getOriginalAndAlternateAnswers } from './utils/answer-helper';
import { OfferType } from '@minna-technologies/core-types/tech/minna/core/api/integration/actions/v3/models';

const View = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 600px;
  flex: 1;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledCard = styled(Card)`
  padding: 12px;
  overflow-y: auto;
  max-height: calc(100vh - 290px);
`;
const StyledHeader = styled(Typography)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.primary};
  font-size: 18px;
  margin-bottom: 6px;
`;

const StyledOfferInfoHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledOfferInfo = styled.div`
  padding-left: 12px;
  display: flex;
  flex-direction: row;
`;

interface TicketDetailsProp {
  ticket: ActionPortalTicket;
}

export const TicketDetails = ({ ticket }: TicketDetailsProp) => {
  const localise = useTranslations('TicketDetails');
  const theme = useTheme();
  // Split the answers in "original" and "alternate" answers.
  const { original, alternate } = getOriginalAndAlternateAnswers(ticket.actionDetails.answers);
  const formattedAnswer = (answer: any, isAlternateQuestion: boolean) => {
    switch (answer.questionId) {
      case 'que_mostrecenttransaction': {
        return <TransactionAnswer answer={answer} isAlternateQuestion={isAlternateQuestion} localise={localise} />;
      }
      case 'que_useraddress':
      case 'que_deliveryaddress':
        return <AddressAnswer answer={answer} isAlternateQuestion={isAlternateQuestion} localise={localise} />;
      default:
        return <DefaultAnswer answer={answer} isAlternateQuestion={isAlternateQuestion} localise={localise} />;
    }
  };

  const offerTypeToSubject = (offerType: OfferType) => {
    switch (offerType) {
      case OfferType.DISCOUNT_PERCENTAGE:
        return 'Discount Percentage';
      case OfferType.AMOUNT_REDUCED:
        return 'Amount Reduced';
      case OfferType.ADDITIONAL_SERVICES:
        return 'Additional Services';
      default:
        return '';
    }
  };

  return (
    <View>
      <StyledHeader variant="h6" theme={theme}>
        User information
      </StyledHeader>
      <StyledCard theme={theme}>
        {original.map((answer, index) => {
          return (
            // @ts-ignore - There is an apigen bug/weirdness with how the answer model is modelled vs how it actually looks.
            <Row key={index} data-testid={`ticket-details-answer-${answer.questionId}`}>
              {formattedAnswer(answer, false)}
            </Row>
          );
        })}
        {alternate.map((answer, index) => {
          return (
            // @ts-ignore - There is an apigen bug/weirdness with how the answer model is modelled vs how it actually looks.
            <Row key={index + 'alternate'} data-testid={`ticket-details-answer-${answer.questionId}-alternate`}>
              {formattedAnswer(answer, true)}
            </Row>
          );
        })}
        {(() => {
          if (ticket.actionDetails.actionType === 'RETENTION_OFFER') {
            return (
              <div>
                <StyledOfferInfoHeader>Offer Information</StyledOfferInfoHeader>

                <StyledOfferInfoHeader>
                  Please apply to the following offer to the users subscription
                </StyledOfferInfoHeader>

                <StyledOfferInfo>Offer Type: {offerTypeToSubject(ticket.offerInformation.offerType)}</StyledOfferInfo>

                <StyledOfferInfo>Offer: {ticket.offerInformation.offerText['en-GB'] /*todo lokalise*/}</StyledOfferInfo>

                {ticket.offerInformation.discountPercentage !== undefined ? (
                  <StyledOfferInfo>Discount percentage: {ticket.offerInformation.discountPercentage}</StyledOfferInfo>
                ) : null}

                {ticket.offerInformation.discountAmount !== undefined ? (
                  <StyledOfferInfo>
                    Discount amount:{' '}
                    {ticket.offerInformation.discountAmount + '' + ticket.offerInformation.discountCurrency}
                  </StyledOfferInfo>
                ) : null}

                <StyledOfferInfo>
                  Offer period: {ticket.offerInformation.offerPeriod + ' ' + ticket.offerInformation.offerInterval}
                </StyledOfferInfo>
              </div>
            );
          }
        })()}
        <TicketDetailsAgencyAgreement ticket={ticket} />
      </StyledCard>
    </View>
  );
};
