import type { Theme } from '@mui/material';
import { FormControl, useTheme } from '@mui/material';
import React from 'react';
import type { Control, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { ErrorText, StyledTextField } from './CommonStyles';

export interface TextFieldProps {
  control: Control<any>;

  errors: FieldErrors;

  register: UseFormRegister<FieldValues>;

  propName: string;

  propLabel: string;

  fieldType?: string;

  isRequired?: boolean;

  rows?: number;

  isMultiline?: boolean;

  'data-testid'?: string;
}

export const FormTextField = ({
  control,
  errors,
  register,
  propName,
  propLabel,
  fieldType,
  isRequired,
  rows,
  isMultiline,
  'data-testid': dataTestId,
}: TextFieldProps) => {
  const theme: Theme = useTheme();

  return (
    <FormControl fullWidth error={Boolean(errors[propName])}>
      <Controller
        name={propName}
        control={control}
        defaultValue=""
        rules={{ required: isRequired }}
        render={() => (
          <>
            <StyledTextField
              id="outlined-helperText"
              label={propLabel}
              InputLabelProps={{ shrink: true }}
              inputProps={{ 'data-testid': dataTestId }}
              type={fieldType ? fieldType : 'text'}
              rows={rows ? rows : 1}
              multiline={isMultiline ? isMultiline : false}
              fullWidth
              aria-invalid={errors[propName] ? 'true' : 'false'}
              error={Boolean(errors?.[propName])}
              // @ts-ignore
              helperText={errors?.[propName]?.message}
              {...register(propName, {
                required: isRequired,
                shouldUnregister: true,
                // Prevent more than 4 digit year
                ...(fieldType === 'date'
                  ? { pattern: { value: /^(|\d{4}-[01]\d-[0-3]\d.*)$/, message: 'Invalid date' } }
                  : {}),
              })}
            />
            <ErrorText theme={theme}>
              {errors[propName]?.type === 'required' && errors[propName] ? 'This field is required' : ''}
            </ErrorText>
          </>
        )}
      />
    </FormControl>
  );
};
