/**
 * IMPORTANT in Belgium both street and streetNumber are required.
 *
 *
 * The street field is for most markets expected to be the street's name and
 * the street's number.
 * However in the Belgium market providers require us to provide
 * the street's name and the street's number as separate values.
 *
 *
 * @param careOf
 */
export interface Address {
  city: string;
  postalCode: string;
  careOf?: string;

  /**
   * The building floor.
   */
  floor?: string;

  /**
   * The number of the street.
   */
  streetNumber?: string;

  /**
   * The delivery box number or code.
   */
  box?: string;

  /**
   * The information related to a street, may in some markets include a street number.
   */
  street: string;
}

/**
 * Identifies a branch within a platform, for example "08999"
 */
export type BranchName = string;

export type Cost = number;

/**
 * Money currency.
 */
export type Currency = string;

/**
 * Specialization of Url for http/https only absolute links with validation
 */
export type HttpAbsoluteLink = Url;

export interface Interval {
  amount?: number;
  unit: any;
}

export type IpAddress = string;

export enum Language {
  Flemish = "Flemish",
  Norwegian = "Norwegian",
  AmericanEnglish = "AmericanEnglish",
  English = "English",
  AmericanSpanish = "AmericanSpanish",
  Danish = "Danish",
  French = "French",
  Slovak = "Slovak",
  Finnish = "Finnish",
  Swedish = "Swedish",
  Spanish = "Spanish",
  Dutch = "Dutch",
}

export type Locale = string;

/**
 * Localized version of a message.
 */
export type LocalizedString = string;

export enum Market {
  Spain = "Spain",
  Slovakia = "Slovakia",
  Finland = "Finland",
  UnitedKingdom = "UnitedKingdom",
  Norway = "Norway",
  UnitedStates = "UnitedStates",
  Denmark = "Denmark",
  Belgium = "Belgium",
  Sweden = "Sweden",
  Australia = "Australia",
}

export enum ActionType {
  BLOCK = "BLOCK",
  CANCEL = "CANCEL",
  CHANGE_PAYMENT = "CHANGE_PAYMENT",
  CHANGE_PLAN = "CHANGE_PLAN",
  PAUSE = "PAUSE",
  RESUBSCRIBE = "RESUBSCRIBE",
  RESUME = "RESUME",
  RETENTION_OFFER = "RETENTION_OFFER",
  UNBLOCK = "UNBLOCK",
}

export enum TicketOutcomeVia {
  EMAIL = "EMAIL",
  CHAT = "CHAT",
  PORTAL = "PORTAL",
  CALLING = "CALLING",
}

/**
 * Contains market and platform information
 * that usually go together in APIs and models.
 */
export interface MarketPlatform {
  market: Market;
  platform: object;
}

export type MerchantId = string;

export type Month = number;

/**
 * A person's name. Example "John Doe".
 */
export interface PersonName {
  /**
   * The first name (eg. "John").
   */
  first: string;

  /**
   * The last name (eg. "Doe").
   */
  last: string;
}

export type PhoneNumber = string;

/**
 * Identifies a platform, for example Standalone, Swedbank.
 */
export type PlatformName = string;

/**
 * Defines some isolated geographical areas that are broadly divided by physical characteristics, laws, etc.
 */
export enum Region {
  USA = "USA",
  Other = "Other",
  Europe = "Europe",
  Australia = "Australia",
  Asia = "Asia",
}

export type SignedDocumentId = string;

export interface ThirdPartyIdentifier {
  type: ThirdPartyIdentifierType | string;
}

export type ThirdPartyIdentifierId = string;

/**
 * A third-party identifier for a merchant, such as BudIdentifier, CardAcceptorId etc
 *
 *
 * Don't confuse this with [[payments.MerchantIdentifier]], which represents _blockable_ third-party identifiers
 */
export enum ThirdPartyIdentifierType {
  CardAcceptorId = "CardAcceptorId",
  MerchantName = "MerchantName",
  BudIdentifier = "BudIdentifier",
  CapOneIdentifier = "CapOneIdentifier",
}

/**
 * Used to locate a resource.
 */
export type Url = string;
