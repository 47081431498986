import styled from '@emotion/styled';
import { setResult } from '@minna-technologies/core-action-portal/ActionPortalTicketsApi';
import type {
  ActionPortalTicket,
  ActionPortalTicketResult,
} from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { ActionPortalTicketResultType } from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import type { Theme } from '@mui/material';
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  useTheme,
} from '@mui/material';

import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormTextField } from './FormComponents/FormTextField';
import { TicketFormCancelled } from './FormComponents/TicketFormCancelled';
import { TicketFormOfferApplied } from './FormComponents/TicketFormOfferApplied';
import { TicketFormFixedContract } from './FormComponents/TicketFormFixedContract';
import { TicketFormFreeText } from './FormComponents/TicketFormFreeText';
import { TicketFormPayingViaThirdParty } from './FormComponents/TicketFormPayingViaThirdParty';
import { TicketFormUserDetailsNotMatching } from './FormComponents/TicketFormUserDetailsNotMatching';
import { Responses } from './TicketView';
import { OutcomeGroup, OutcomeGroupComponent } from './FormComponents/OutcomeGroupComponent';
import { ActionType } from '@minna-technologies/core-types/tech/minna/models';
import { TicketFormUserNotEligible } from './FormComponents/TicketFormUserNotEligible';

interface TicketFormProps {
  token: string;
  ticket: ActionPortalTicket;
  onSubmitCallback: (result: Responses) => void;
}

const View = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  flex: 1;
`;

const StyledInnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledHeader = styled(Typography)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.primary};
  font-size: 18px;
  margin-bottom: 6px;
`;

const StyledCardContent = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const StyledCard = styled(Card)`
  padding: 16px;
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;

const ButtonDiv = styled.div`
  float: right;
  margin-top: 8px;
  align-self: flex-end;
`;

const MessageDiv = styled.div`
  flex: 1;
`;
const HelpIcon = styled(HelpOutlineIcon)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.grey[400]};
  margin-left: 4px;
`;

const RadioLabel = styled.div`
  display: flex;
  align-items: center;
`;

const couldNotBeCancelledOutcomes = [
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultFreeAccount,
    label: 'User is on a free account',
    tooltipText: 'Select if the user is on a free account and there is no paid account that requires cancelling',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultPayingViaThirdParty,
    label: 'User is paying via a third party',
    tooltipText: 'Select if subscription is billed and managed through the third party',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultFixedContract,
    label: 'User is in a fixed contract',
    tooltipText: 'Select if user is on a fixed contract and you cannot cancel the subscription',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultUserNotFound,
    label: 'User not found in the system',
    tooltipText: 'Select if you were unable to find the user in your system with the given information',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultMultipleAccounts,
    label: 'User has multiple accounts',
    tooltipText: 'Select if user has more than one paid account and you are unsure which one to cancel',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultUserDetailsNotMatching,
    label: 'User details not matching',
    tooltipText:
      'Select if there was a partial match in finding the user but you need more information to verify the account before taking any action',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultFreeText,
    label: 'Other',
    tooltipText:
      'Select if none of the above outcomes fit the description of why the subscription could not be cancelled',
  },
];
const offerCouldNotBeAppliedOutcomes = [
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultUserNotEligible,
    label: 'User not eligible',
    tooltipText: 'Select if user is not eligible to apply the offer',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultFreeAccount,
    label: 'User is on a free account',
    tooltipText: 'Select if the user is on a free account and there is no paid account that eligible for offer',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultPayingViaThirdParty,
    label: 'User is paying via a third party',
    tooltipText: 'Select if subscription is billed and managed through the third party',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultFixedContract,
    label: 'User is in a fixed contract',
    tooltipText: 'Select if user is on a fixed contract and you cannot cancel the subscription',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultUserNotFound,
    label: 'User not found in the system',
    tooltipText: 'Select if you were unable to find the user in your system with the given information',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultMultipleAccounts,
    label: 'User has multiple accounts',
    tooltipText: 'Select if user has more than one paid account and you are unsure which one to apply offer',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultUserDetailsNotMatching,
    label: 'User details not matching',
    tooltipText:
      'Select if there was a partial match in finding the user but you need more information to verify the account before taking any action',
  },
  {
    value: ActionPortalTicketResultType.ActionPortalTicketResultFreeText,
    label: 'Other',
    tooltipText:
      'Select if none of the above outcomes fit the description of why offer for the subscription could not be applied',
  },
];

export const TicketForm = ({ token, ticket, onSubmitCallback }: TicketFormProps) => {
  const theme: Theme = useTheme();
  const [resultType, setResultType] = useState(' ');
  const [activeOutcomeGroup, setActiveOutcomeGroup] = useState<OutcomeGroup>(OutcomeGroup.NONE);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, ...formState },
    getValues,
  } = useForm();
  const childFormRef = useRef<any>();
  const disableSubmit = resultType === ' ' || isSubmitting;
  const isMessageToUserRequired = ticket.market === 'Sweden';

  // Reset state when ticket is updated.
  useEffect(() => {
    setActiveOutcomeGroup(OutcomeGroup.NONE);
    setResultType(' ');
  }, [ticket]);

  const selectedForm = () => {
    switch (resultType) {
      case ActionPortalTicketResultType.ActionPortalTicketResultCancelled:
        return (
          <TicketFormCancelled
            resultType={resultType}
            onSubmitCallback={async (content) => sendRequest(content)}
            ref={childFormRef}
          />
        );
      case ActionPortalTicketResultType.ActionPortalTicketResultFreeAccount:
        return <></>;
      case ActionPortalTicketResultType.ActionPortalTicketResultPayingViaThirdParty:
        return (
          <TicketFormPayingViaThirdParty
            resultType={resultType}
            onSubmitCallback={async (content) => sendRequest(content)}
            ref={childFormRef}
          />
        );
      case ActionPortalTicketResultType.ActionPortalTicketResultFixedContract:
        return (
          <TicketFormFixedContract
            resultType={resultType}
            onSubmitCallback={async (content) => sendRequest(content)}
            ref={childFormRef}
          />
        );
      case ActionPortalTicketResultType.ActionPortalTicketResultUserNotFound:
        return <></>;
      case ActionPortalTicketResultType.ActionPortalTicketResultMultipleAccounts:
        return <></>;
      case ActionPortalTicketResultType.ActionPortalTicketResultUserDetailsNotMatching:
        return (
          <TicketFormUserDetailsNotMatching
            ticket={ticket}
            resultType={resultType}
            onSubmitCallback={async (content) => sendRequest(content)}
            ref={childFormRef}
          />
        );
      case ActionPortalTicketResultType.ActionPortalTicketResultFreeText:
        return (
          <TicketFormFreeText
            resultType={resultType}
            onSubmitCallback={async (content) => sendRequest(content)}
            ref={childFormRef}
          />
        );
      case ActionPortalTicketResultType.ActionPortalTicketResultOfferApplied:
        return (
          <TicketFormOfferApplied
            resultType={resultType}
            onSubmitCallback={async (content) => sendRequest(content)}
            ref={childFormRef}
          />
        );
      case ActionPortalTicketResultType.ActionPortalTicketResultUserNotEligible:
        return (
          <TicketFormUserNotEligible
            resultType={resultType}
            onSubmitCallback={async (content) => sendRequest(content)}
            ref={childFormRef}
          />
        );
      default:
        return <div>Cancelled</div>;
    }
  };

  const onSubmitClick = () => {
    return childFormRef.current
      ? childFormRef.current()
      : sendRequest({ type: resultType } as ActionPortalTicketResult);
  };

  const sendRequest = async (content: ActionPortalTicketResult) => {
    const data = {
      ticketId: ticket.ticketId,
      result: {
        ...content,
        messageToUser: getValues('messageToUser') === '' ? null : getValues('messageToUser'),
      },
      token: token,
    };
    await setResult(data)
      .then(() => {
        onSubmitCallback(Responses.SUCCESS);
        return;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          onSubmitCallback(Responses.BAD_REQUEST);
          return;
        } else if (error.response.status === 403) {
          onSubmitCallback(Responses.UNAUTHORIZED);
          return;
        } else {
          onSubmitCallback(Responses.INTERNAL_SERVER_ERROR);
          return;
        }
      });
  };
  switch (ticket.actionDetails.actionType) {
    case ActionType.RETENTION_OFFER:
      return (
        <View>
          <form onSubmit={handleSubmit(onSubmitClick)} data-testid="ticket-form-submit">
            <StyledInnerDiv>
              <StyledFormControl>
                <FormLabel>
                  <StyledHeader theme={theme} variant="h6">{`The user's subscription`}</StyledHeader>
                </FormLabel>
                <Card>
                  <StyledCardContent>
                    <OutcomeGroupComponent
                      currentActiveValue={activeOutcomeGroup}
                      selfValue={OutcomeGroup.OFFER_COULD_BE_APPLIED}
                      onGroupClicked={(group) => {
                        setActiveOutcomeGroup(group);
                        setResultType(ActionPortalTicketResultType.ActionPortalTicketResultOfferApplied);
                      }}
                    >
                      <div style={{ paddingTop: '1rem' }}>{selectedForm()}</div>
                    </OutcomeGroupComponent>
                    <OutcomeGroupComponent
                      currentActiveValue={activeOutcomeGroup}
                      selfValue={OutcomeGroup.OFFER_COULD_NOT_BE_APPLIED}
                      onGroupClicked={setActiveOutcomeGroup}
                    >
                      <StyledCard elevation={0}>
                        <RadioGroup
                          aria-labelledby="result-type-label"
                          name="result-type-radio-group"
                          value={resultType}
                          onChange={(e) => setResultType(e.target.value as ActionPortalTicketResultType)}
                        >
                          {offerCouldNotBeAppliedOutcomes.map((option) => (
                            <span key={option.value}>
                              <FormControlLabel
                                value={option.value}
                                control={<Radio />}
                                label={
                                  <RadioLabel>
                                    <Typography variant="body1">{option.label}</Typography>
                                    <Tooltip title={option.tooltipText}>
                                      <HelpIcon fontSize="inherit" theme={theme} />
                                    </Tooltip>
                                  </RadioLabel>
                                }
                                labelPlacement="end"
                              />
                              {resultType === option.value && selectedForm()}
                            </span>
                          ))}
                        </RadioGroup>
                      </StyledCard>
                    </OutcomeGroupComponent>
                  </StyledCardContent>
                </Card>
              </StyledFormControl>
              <MessageDiv>
                <FormTextField
                  control={control}
                  errors={errors}
                  register={register}
                  propName="messageToUser"
                  propLabel={isMessageToUserRequired ? 'Message to user' : 'Message to user (Optional)'}
                  isRequired={isMessageToUserRequired}
                  rows={4}
                  isMultiline
                />
              </MessageDiv>
            </StyledInnerDiv>
            <ButtonDiv>
              <Button
                type="submit"
                data-testid="ticket-form-submit-button"
                variant="contained"
                disabled={disableSubmit}
              >
                Submit
              </Button>
            </ButtonDiv>
          </form>
        </View>
      );
    default:
      return (
        <View>
          <form onSubmit={handleSubmit(onSubmitClick)} data-testid="ticket-form-submit">
            <StyledInnerDiv>
              <StyledFormControl>
                <FormLabel>
                  <StyledHeader theme={theme} variant="h6">{`The user's subscription`}</StyledHeader>
                </FormLabel>
                <Card>
                  <StyledCardContent>
                    <OutcomeGroupComponent
                      currentActiveValue={activeOutcomeGroup}
                      selfValue={OutcomeGroup.COULD_BE_CANCELLED}
                      onGroupClicked={(group) => {
                        setActiveOutcomeGroup(group);
                        setResultType(ActionPortalTicketResultType.ActionPortalTicketResultCancelled);
                      }}
                    >
                      <div style={{ paddingTop: '1rem' }}>{selectedForm()}</div>
                    </OutcomeGroupComponent>
                    <OutcomeGroupComponent
                      currentActiveValue={activeOutcomeGroup}
                      selfValue={OutcomeGroup.COULD_NOT_BE_CANCELLED}
                      onGroupClicked={setActiveOutcomeGroup}
                    >
                      <StyledCard elevation={0}>
                        <RadioGroup
                          aria-labelledby="result-type-label"
                          name="result-type-radio-group"
                          value={resultType}
                          onChange={(e) => setResultType(e.target.value as ActionPortalTicketResultType)}
                        >
                          {couldNotBeCancelledOutcomes.map((option) => (
                            <span key={option.value}>
                              <FormControlLabel
                                value={option.value}
                                control={<Radio />}
                                label={
                                  <RadioLabel>
                                    <Typography variant="body1">{option.label}</Typography>
                                    <Tooltip title={option.tooltipText}>
                                      <HelpIcon fontSize="inherit" theme={theme} />
                                    </Tooltip>
                                  </RadioLabel>
                                }
                                labelPlacement="end"
                              />
                              {resultType === option.value && selectedForm()}
                            </span>
                          ))}
                        </RadioGroup>
                      </StyledCard>
                    </OutcomeGroupComponent>
                  </StyledCardContent>
                </Card>
              </StyledFormControl>
              <MessageDiv>
                <FormTextField
                  control={control}
                  errors={errors}
                  register={register}
                  propName="messageToUser"
                  propLabel={isMessageToUserRequired ? 'Message to user' : 'Message to user (Optional)'}
                  isRequired={isMessageToUserRequired}
                  rows={4}
                  isMultiline
                />
              </MessageDiv>
            </StyledInnerDiv>
            <ButtonDiv>
              <Button
                type="submit"
                data-testid="ticket-form-submit-button"
                variant="contained"
                disabled={disableSubmit}
              >
                Submit
              </Button>
            </ButtonDiv>
          </form>
        </View>
      );
  }
};
