import React from 'react';
import { ErrorComponent } from './ErrorComponent';

export const TicketExpired = () => {
  const errorText = 'This request has expired.';
  const errorDescription =
    'To see more open requests, please open the Minna Action Portal link\nfrom another request in your email.';
  const imageUrl = '/403-expired.svg';
  return <ErrorComponent imageUrl={imageUrl} errorText={errorText} errorDescription={errorDescription} />;
};
