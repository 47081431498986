import React from 'react';
import type { Theme } from '@mui/material';
import { Card, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { AgeChip } from '@minna-technologies/shared/agechip/AgeChip';
import type { ActionPortalTicket } from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { getUserEmailFromAnswers } from './utils/answer-helper';
import { ActionType } from '@minna-technologies/core-types/tech/minna/models';

const StyledTicketCard = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 50px;
  border-bottom: 1px solid ${(props: { theme: Theme }) => props.theme.palette.divider};
  padding: 8px 14px;
  border-radius: 0;
  align-items: start;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: ${(props: { theme: Theme }) => props.theme.palette.action.hover};
  }
`;

const StyledText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 206px;
`;

const SelectedTicketCard = styled(StyledTicketCard)(
  ({ theme }: ThemeProp) => `
  background: ${theme.palette.action.selected};
`
);
interface TicketCardProps {
  ticket: ActionPortalTicket;
  onClick(): void;
  selected: boolean;
}
interface ThemeProp {
  theme: Theme;
}

const actionTypeToSubject = (actionType: ActionType) => {
  switch (actionType) {
    case ActionType.BLOCK:
      return 'Block';
    case ActionType.CANCEL:
      return 'Cancellation';
    case ActionType.CHANGE_PLAN:
      return 'Change plan';
    case ActionType.CHANGE_PAYMENT:
      return 'Change payment method';
    case ActionType.PAUSE:
      return 'Pause';
    case ActionType.RESUBSCRIBE:
      return 'Resubscribe';
    case ActionType.RESUME:
      return 'Resume';
    case ActionType.RETENTION_OFFER:
      return 'Retention Offer';
    case ActionType.UNBLOCK:
      return 'Unblock';
    default:
      return '';
  }
};

export const TicketCard = ({ ticket, onClick, selected }: TicketCardProps) => {
  const theme: Theme = useTheme();

  const age = ticket.age;
  const rightText = <AgeChip age={age} small />;
  const fontWeight = ticket.open ? 'fontWeightBold' : undefined;
  const CardComponent = selected ? SelectedTicketCard : StyledTicketCard;
  const userEmail = getUserEmailFromAnswers(ticket.actionDetails.answers);
  return (
    <CardComponent elevation={0} data-testid={['ticket-card-card']} theme={theme} onClick={onClick}>
      <div>
        <div>
          <StyledText variant="body2" fontWeight={fontWeight} data-testid="ticket-card-user-email">
            {userEmail.toLowerCase()}
          </StyledText>
          <StyledText variant="body2" fontWeight={fontWeight} data-testid="ticket-card-action-type">
            {
              actionTypeToSubject(ticket.actionDetails.actionType) // todo localize
            }
          </StyledText>
        </div>
      </div>
      <div>{rightText}</div>
    </CardComponent>
  );
};
