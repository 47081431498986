import type { Theme } from '@mui/material';
import { Card } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { Typography, useTheme } from '@mui/material';
import { ArrowDownward, ArrowUpward, Search } from '@mui/icons-material';
import React from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';

const StyledCard = styled(Card)`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 0;
`;

const TextContainer = styled.div<{ theme: Theme }>`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 14px;
`;

const TextContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface TicketListHeaderProps {
  sortKey: string;
  sortDirection: number;
  search: string;
  updateSort(sortKey: string): void;
  updateSearch(search: string): void;
}

export const TicketListHeader = ({
  sortKey,
  sortDirection,
  search,
  updateSort,
  updateSearch,
}: TicketListHeaderProps) => {
  const theme = useTheme();
  return (
    <>
      <StyledCard>
        <TextField
          placeholder={'Search'}
          value={search}
          onChange={(e) => updateSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </StyledCard>
      <TextContainer theme={theme}>
        <TextContent onClick={() => updateSort('user')} data-testid="ticket-list-sort-user">
          <Typography variant="body2">User{/*todo lokalise*/}</Typography>
          {sortKey === 'user' && sortDirection > 0 && <ArrowUpward fontSize="small" />}
          {sortKey === 'user' && sortDirection < 0 && <ArrowDownward fontSize="small" />}
        </TextContent>
        <TextContent onClick={() => updateSort('age')} data-testid="ticket-list-sort-age">
          <Typography variant="body2" data-testid="sort-button">
            Age{/*todo lokalise*/}
          </Typography>
          {sortKey === 'age' && sortDirection > 0 && <ArrowUpward fontSize="small" />}
          {sortKey === 'age' && sortDirection < 0 && <ArrowDownward fontSize="small" />}
        </TextContent>
      </TextContainer>
    </>
  );
};
