import styled from '@emotion/styled';
import { Button, Container, type Theme, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CenteredTextContent = styled.div`
  margin-top: 2px;
  margin-bottom: 32px;
  text-align: center;
`;

const StyledButtonContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;

interface ThemeProp {
  theme: Theme;
}
interface ErrorComponentProps {
  imageUrl: string;
  errorText?: string;
  errorDescription?: string;
  buttonText?: string;
  buttonUrl?: string;
}
export const ErrorComponent = ({
  imageUrl,
  errorText,
  errorDescription,
  buttonText,
  buttonUrl,
}: ErrorComponentProps) => {
  const theme: Theme = useTheme();

  const StyledSubTitle = styled(Typography)`
    color: ${theme.palette.text.secondary};
    white-space: pre-wrap;
  `;

  return (
    <Container>
      <CenteredContent>
        <img src={imageUrl} alt="Error" />
        <CenteredTextContent>
          <Typography variant="h5" gutterBottom>
            {errorText}
          </Typography>
          {errorDescription && (
            <StyledSubTitle variant="body1" theme={theme}>
              {errorDescription}
            </StyledSubTitle>
          )}
        </CenteredTextContent>
        {buttonUrl && (
          <Button component="a" href={buttonUrl} variant="contained" color="primary">
            {buttonText}
          </Button>
        )}
        {!buttonUrl && buttonText && (
          <StyledButtonContainer>
            <Button variant="contained" color="primary">
              {buttonText}
            </Button>
          </StyledButtonContainer>
        )}
      </CenteredContent>
    </Container>
  );
};
