"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YesNoQuestionId = exports.StringQuestionId = exports.State = exports.SelectQuestionId = exports.QuestionType = exports.QuestionId = exports.PhoneNumberQuestionId = exports.PersonalNumberQuestionId = exports.OfferType = exports.MostRecentTransactionId = exports.MethodType = exports.Last4DigitsQuestionId = exports.FullNameQuestionId = exports.First6DigitsQuestionId = exports.EmailQuestionId = exports.DateQuestionId = exports.Conclusion = exports.CallToActionType = exports.AnswerType = exports.AddressQuestionId = exports.AdditionalInformationType = exports.ActionType = exports.ActionOutcomeType = void 0;
var ActionOutcomeType;
(function (ActionOutcomeType) {
    ActionOutcomeType["PROCESSED"] = "PROCESSED";
    ActionOutcomeType["ALREADY_PROCESSED_WITH_UPCOMING_PAYMENTS"] = "ALREADY_PROCESSED_WITH_UPCOMING_PAYMENTS";
    ActionOutcomeType["ACCOUNT_NOT_VERIFIED"] = "ACCOUNT_NOT_VERIFIED";
    ActionOutcomeType["TECHNICAL_ERROR"] = "TECHNICAL_ERROR";
    ActionOutcomeType["FIXED_CONTRACT"] = "FIXED_CONTRACT";
    ActionOutcomeType["FREE_ACCOUNT"] = "FREE_ACCOUNT";
    ActionOutcomeType["PROCESSED_WITH_UPCOMING_PAYMENTS"] = "PROCESSED_WITH_UPCOMING_PAYMENTS";
    ActionOutcomeType["BILLED_THROUGH_THIRD_PARTY"] = "BILLED_THROUGH_THIRD_PARTY";
    ActionOutcomeType["USER_NOT_ELIGIBLE"] = "USER_NOT_ELIGIBLE";
    ActionOutcomeType["HAS_MULTIPLE_SUBSCRIPTIONS"] = "HAS_MULTIPLE_SUBSCRIPTIONS";
    ActionOutcomeType["ALREADY_PROCESSED"] = "ALREADY_PROCESSED";
})(ActionOutcomeType || (exports.ActionOutcomeType = ActionOutcomeType = {}));
/**
 * An Action that can be requested of a subscription merchant
 */
var ActionType;
(function (ActionType) {
    ActionType["RETENTION_OFFER"] = "RETENTION_OFFER";
    ActionType["CHANGE_PLAN"] = "CHANGE_PLAN";
    ActionType["PAUSE"] = "PAUSE";
    ActionType["RESUBSCRIBE"] = "RESUBSCRIBE";
    ActionType["RESUME"] = "RESUME";
    ActionType["CANCEL"] = "CANCEL";
    ActionType["BLOCK"] = "BLOCK";
    ActionType["UNBLOCK"] = "UNBLOCK";
    ActionType["CHANGE_PAYMENT"] = "CHANGE_PAYMENT";
})(ActionType || (exports.ActionType = ActionType = {}));
/**
 * An identifier classifying the type of information provided.
 */
var AdditionalInformationType;
(function (AdditionalInformationType) {
    AdditionalInformationType["EARLY_TERMINATION_FEE_DISCLAIMER"] = "EARLY_TERMINATION_FEE_DISCLAIMER";
    AdditionalInformationType["CHANGE_PLAN"] = "CHANGE_PLAN";
    AdditionalInformationType["BINDING_PERIOD_DISCLAIMER"] = "BINDING_PERIOD_DISCLAIMER";
    AdditionalInformationType["DEVICE_RETURN_DISCLAIMER"] = "DEVICE_RETURN_DISCLAIMER";
    AdditionalInformationType["PAUSE"] = "PAUSE";
    AdditionalInformationType["NOTICE_PERIOD_DISCLAIMER"] = "NOTICE_PERIOD_DISCLAIMER";
})(AdditionalInformationType || (exports.AdditionalInformationType = AdditionalInformationType = {}));
var AddressQuestionId;
(function (AddressQuestionId) {
    AddressQuestionId["que_useraddress"] = "que_useraddress";
    AddressQuestionId["que_deliveryaddress"] = "que_deliveryaddress";
})(AddressQuestionId || (exports.AddressQuestionId = AddressQuestionId = {}));
/**
 * An answer submitted for the question posed to the user. Each answer has a max length of 255 characters.
 */
var AnswerType;
(function (AnswerType) {
    AnswerType["YES_NO"] = "YES_NO";
    AnswerType["SELECT"] = "SELECT";
    AnswerType["PERSONAL_NUMBER"] = "PERSONAL_NUMBER";
    AnswerType["PHONE_NUMBER"] = "PHONE_NUMBER";
    AnswerType["FIRST_6_DIGITS"] = "FIRST_6_DIGITS";
    AnswerType["TRANSACTION"] = "TRANSACTION";
    AnswerType["ADDRESS"] = "ADDRESS";
    AnswerType["EMAIL"] = "EMAIL";
    AnswerType["DATE"] = "DATE";
    AnswerType["STRING"] = "STRING";
    AnswerType["FULL_NAME"] = "FULL_NAME";
    AnswerType["LAST_4_DIGITS"] = "LAST_4_DIGITS";
})(AnswerType || (exports.AnswerType = AnswerType = {}));
/**
 * The type of call to action
 */
var CallToActionType;
(function (CallToActionType) {
    CallToActionType["URL"] = "URL";
    CallToActionType["PHONE_NUMBER"] = "PHONE_NUMBER";
})(CallToActionType || (exports.CallToActionType = CallToActionType = {}));
/**
 * The conclusion of an Action. Initial value is <code>UNKNOWN</code>.
 */
var Conclusion;
(function (Conclusion) {
    Conclusion["UNKNOWN"] = "UNKNOWN";
    Conclusion["SUCCESSFUL"] = "SUCCESSFUL";
    Conclusion["UNSUCCESSFUL"] = "UNSUCCESSFUL";
})(Conclusion || (exports.Conclusion = Conclusion = {}));
var DateQuestionId;
(function (DateQuestionId) {
    DateQuestionId["que_birthdate"] = "que_birthdate";
    DateQuestionId["que_cardexpirationdate"] = "que_cardexpirationdate";
    DateQuestionId["que_servicerenewaldate"] = "que_servicerenewaldate";
})(DateQuestionId || (exports.DateQuestionId = DateQuestionId = {}));
var EmailQuestionId;
(function (EmailQuestionId) {
    EmailQuestionId["que_contactemail"] = "que_contactemail";
    EmailQuestionId["que_merchantemail"] = "que_merchantemail";
})(EmailQuestionId || (exports.EmailQuestionId = EmailQuestionId = {}));
var First6DigitsQuestionId;
(function (First6DigitsQuestionId) {
    First6DigitsQuestionId["que_6digits"] = "que_6digits";
})(First6DigitsQuestionId || (exports.First6DigitsQuestionId = First6DigitsQuestionId = {}));
var FullNameQuestionId;
(function (FullNameQuestionId) {
    FullNameQuestionId["que_fullname"] = "que_fullname";
})(FullNameQuestionId || (exports.FullNameQuestionId = FullNameQuestionId = {}));
var Last4DigitsQuestionId;
(function (Last4DigitsQuestionId) {
    Last4DigitsQuestionId["que_4digits"] = "que_4digits";
})(Last4DigitsQuestionId || (exports.Last4DigitsQuestionId = Last4DigitsQuestionId = {}));
/**
 * The method in which the Action will be handled by Minna
 */
var MethodType;
(function (MethodType) {
    MethodType["MERCHANT_GUIDE"] = "MERCHANT_GUIDE";
    MethodType["MERCHANT_DIRECT"] = "MERCHANT_DIRECT";
    MethodType["MERCHANT_OUTREACH"] = "MERCHANT_OUTREACH";
})(MethodType || (exports.MethodType = MethodType = {}));
var MostRecentTransactionId;
(function (MostRecentTransactionId) {
    MostRecentTransactionId["que_mostrecenttransaction"] = "que_mostrecenttransaction";
})(MostRecentTransactionId || (exports.MostRecentTransactionId = MostRecentTransactionId = {}));
/**
 * The type of the offer.
 * Gives an indication what the offer includes for the user.
 */
var OfferType;
(function (OfferType) {
    OfferType["DISCOUNT_PERCENTAGE"] = "DISCOUNT_PERCENTAGE";
    OfferType["AMOUNT_REDUCED"] = "AMOUNT_REDUCED";
    OfferType["ADDITIONAL_SERVICES"] = "ADDITIONAL_SERVICES";
})(OfferType || (exports.OfferType = OfferType = {}));
var PersonalNumberQuestionId;
(function (PersonalNumberQuestionId) {
    PersonalNumberQuestionId["que_personalnumber"] = "que_personalnumber";
})(PersonalNumberQuestionId || (exports.PersonalNumberQuestionId = PersonalNumberQuestionId = {}));
var PhoneNumberQuestionId;
(function (PhoneNumberQuestionId) {
    PhoneNumberQuestionId["que_contactnumber"] = "que_contactnumber";
    PhoneNumberQuestionId["que_servicephonenumber"] = "que_servicephonenumber";
})(PhoneNumberQuestionId || (exports.PhoneNumberQuestionId = PhoneNumberQuestionId = {}));
/**
 * The unique id of a question. Question ids are prefixed with que_.
 */
var QuestionId;
(function (QuestionId) {
    QuestionId["que_personalnumber"] = "que_personalnumber";
    QuestionId["que_birthdate"] = "que_birthdate";
    QuestionId["que_fullname"] = "que_fullname";
    QuestionId["que_pauseduration"] = "que_pauseduration";
    QuestionId["que_servicenumber"] = "que_servicenumber";
    QuestionId["que_serviceusedat"] = "que_serviceusedat";
    QuestionId["que_additionalinfo"] = "que_additionalinfo";
    QuestionId["que_encryptedcarddetails"] = "que_encryptedcarddetails";
    QuestionId["que_merchantemail"] = "que_merchantemail";
    QuestionId["que_changeplan"] = "que_changeplan";
    QuestionId["que_deliveryaddress"] = "que_deliveryaddress";
    QuestionId["que_visacaid"] = "que_visacaid";
    QuestionId["que_cardexpirationdate"] = "que_cardexpirationdate";
    QuestionId["que_servicerenewaldate"] = "que_servicerenewaldate";
    QuestionId["que_useraddress"] = "que_useraddress";
    QuestionId["que_mostrecenttransaction"] = "que_mostrecenttransaction";
    QuestionId["que_contactnumber"] = "que_contactnumber";
    QuestionId["que_contactemail"] = "que_contactemail";
    QuestionId["que_offer"] = "que_offer";
    QuestionId["que_guidecompleted"] = "que_guidecompleted";
    QuestionId["que_purchaselocation"] = "que_purchaselocation";
    QuestionId["que_4digits"] = "que_4digits";
    QuestionId["que_servicephonenumber"] = "que_servicephonenumber";
    QuestionId["que_merchantid"] = "que_merchantid";
    QuestionId["que_postcode"] = "que_postcode";
    QuestionId["que_product"] = "que_product";
    QuestionId["que_6digits"] = "que_6digits";
})(QuestionId || (exports.QuestionId = QuestionId = {}));
var QuestionType;
(function (QuestionType) {
    QuestionType["YES_NO"] = "YES_NO";
    QuestionType["SELECT"] = "SELECT";
    QuestionType["PERSONAL_NUMBER"] = "PERSONAL_NUMBER";
    QuestionType["PHONE_NUMBER"] = "PHONE_NUMBER";
    QuestionType["FIRST_6_DIGITS"] = "FIRST_6_DIGITS";
    QuestionType["TRANSACTION"] = "TRANSACTION";
    QuestionType["ADDRESS"] = "ADDRESS";
    QuestionType["EMAIL"] = "EMAIL";
    QuestionType["DATE"] = "DATE";
    QuestionType["STRING"] = "STRING";
    QuestionType["FULL_NAME"] = "FULL_NAME";
    QuestionType["LAST_4_DIGITS"] = "LAST_4_DIGITS";
})(QuestionType || (exports.QuestionType = QuestionType = {}));
var SelectQuestionId;
(function (SelectQuestionId) {
    SelectQuestionId["que_pauseduration"] = "que_pauseduration";
    SelectQuestionId["que_changeplan"] = "que_changeplan";
})(SelectQuestionId || (exports.SelectQuestionId = SelectQuestionId = {}));
/**
 * The current state of an Action
 */
var State;
(function (State) {
    State["IN_PROGRESS"] = "IN_PROGRESS";
    State["EXPIRED"] = "EXPIRED";
    State["COMPLETED"] = "COMPLETED";
    State["WAITING_FOR_USER"] = "WAITING_FOR_USER";
    State["INITIATED"] = "INITIATED";
})(State || (exports.State = State = {}));
var StringQuestionId;
(function (StringQuestionId) {
    StringQuestionId["que_encryptedcarddetails"] = "que_encryptedcarddetails";
    StringQuestionId["que_visacaid"] = "que_visacaid";
    StringQuestionId["que_purchaselocation"] = "que_purchaselocation";
    StringQuestionId["que_servicenumber"] = "que_servicenumber";
    StringQuestionId["que_serviceusedat"] = "que_serviceusedat";
    StringQuestionId["que_product"] = "que_product";
    StringQuestionId["que_additionalinfo"] = "que_additionalinfo";
    StringQuestionId["que_merchantid"] = "que_merchantid";
    StringQuestionId["que_postcode"] = "que_postcode";
})(StringQuestionId || (exports.StringQuestionId = StringQuestionId = {}));
var YesNoQuestionId;
(function (YesNoQuestionId) {
    YesNoQuestionId["que_guidecompleted"] = "que_guidecompleted";
    YesNoQuestionId["que_offer"] = "que_offer";
})(YesNoQuestionId || (exports.YesNoQuestionId = YesNoQuestionId = {}));
