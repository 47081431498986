import styled from '@emotion/styled';
import type { ActionPortalTicket } from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { Theme, Typography } from '@mui/material';
import React from 'react';

interface TicketDetailsAgencyAgreementProps {
  ticket: ActionPortalTicket;
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 10% 1fr 1fr;
  grid-gap: 20px;
`;

const GridItemWide = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

const GridItemMultiLine = styled.div`
  white-space: pre-wrap;
`;

const StyledTypography = styled(Typography)`
  color: #00000099;
`;

const TicketDetailsAgencyAgreement = ({ ticket }: TicketDetailsAgencyAgreementProps) => {
  return (
    <GridContainer>
      <GridItemWide>
        <h4>{ticket.loaText.header}</h4>
      </GridItemWide>
      <GridItemWide>
        <StyledTypography>{ticket.loaText.mainText}</StyledTypography>
      </GridItemWide>
      <GridItemMultiLine>
        <StyledTypography>{ticket.loaText.agentText.join('\n')}</StyledTypography>
      </GridItemMultiLine>
      <GridItemMultiLine>
        <StyledTypography>{ticket.loaText.subAgentText.join('\n')}</StyledTypography>
      </GridItemMultiLine>
      <GridItemMultiLine>
        <StyledTypography>{ticket.loaText.principalText.join('\n')}</StyledTypography>
      </GridItemMultiLine>
      <GridItemMultiLine>
        <StyledTypography>{ticket.loaText.concernedPartyText.join('\n')}</StyledTypography>
      </GridItemMultiLine>
    </GridContainer>
  );
};

export default TicketDetailsAgencyAgreement;
