import React, { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import type {
  ActionPortalTicketResult,
  ActionPortalTicketResultType,
  ActionPortalTicketResultUserNotEligible,
} from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { FormContainer } from './CommonStyles';
import { FormTextField } from './FormTextField';

interface TicketFormProps {
  resultType: ActionPortalTicketResultType;
  onSubmitCallback: (resultContent: ActionPortalTicketResult) => void;
  ref?: React.Ref<unknown>;
}

export const TicketFormUserNotEligible: React.FC<TicketFormProps> = forwardRef(
  ({ resultType, onSubmitCallback }, ref) => {
    const {
      register,
      control,
      formState: { errors },
      handleSubmit,
    } = useForm();
    const onSubmit = (data: ActionPortalTicketResultUserNotEligible) => {
      onSubmitCallback({
        ...data,
        reason: data.reason ? data.reason : null,
        type: resultType,
      } as ActionPortalTicketResult);
    };

    // Expose the onSubmit method through the ref
    useImperativeHandle(ref, () => handleSubmit(onSubmit));
    return (
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTextField
            control={control}
            errors={errors}
            register={register}
            propName="reason"
            propLabel="Reason"
            fieldType="text"
          />

          <input type="submit" hidden />
        </form>
      </FormContainer>
    );
  }
);

TicketFormUserNotEligible.displayName = 'TicketFormUserNotEligible';
