import type { Context, Dispatch, ReactNode } from 'react';
import { createContext, useContext, useReducer } from 'react';
import type { ActionPortalTicket } from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { markAsRead } from '@minna-technologies/core-action-portal/ActionPortalTicketsApi';
import { getUserEmailFromAnswers, getUserNameFromAnswers } from './utils/answer-helper';
import * as Sentry from '@sentry/nextjs';

interface TicketsContext {
  token: string;
  tickets: ActionPortalTicket[];
  selectedTicket?: ActionPortalTicket;
  search?: string;
}

interface TicketsProviderProps {
  children: ReactNode;
  initialState?: TicketsContext;
}

const defaultInitialState: TicketsContext = {
  token: '',
  tickets: [],
};

export function TicketsProvider({ children, initialState }: TicketsProviderProps) {
  const [ticketsContext, dispatch] = useReducer(ticketsReducer, initialState ? initialState : defaultInitialState);

  return (
    <TicketsContext.Provider value={ticketsContext}>
      <TicketsDispatchContext.Provider value={dispatch}>{children}</TicketsDispatchContext.Provider>
    </TicketsContext.Provider>
  );
}

export const TicketsContext: Context<TicketsContext> = createContext(defaultInitialState);
export const TicketsDispatchContext: Context<Dispatch<TicketAction>> = createContext(null);

export enum TicketActionType {
  SUBMITTED_TICKET = 'submittedTicket',
  SELECT_TICKET = 'selectTicket',
  REFRESH = 'refresh',
  UPDATED = 'updated',
  UPDATE_SEARCH = 'updateSearch',
}

export interface SubmittedTicketAction {
  type: TicketActionType.SUBMITTED_TICKET;
  ticket: ActionPortalTicket;
}

export interface SelectTicketAction {
  type: TicketActionType.SELECT_TICKET;
  ticketId: string;
}

export interface RefreshTicketsAction {
  type: TicketActionType.REFRESH;
  tickets: ActionPortalTicket[];
}

export interface UpdatedAction {
  type: TicketActionType.UPDATED;
  ticket: ActionPortalTicket;
}

export interface UpdateSearchAction {
  type: TicketActionType.UPDATE_SEARCH;
  search: string;
}

type TicketAction =
  | SubmittedTicketAction
  | SelectTicketAction
  | RefreshTicketsAction
  | UpdatedAction
  | UpdateSearchAction;

function ticketsReducer(ticketsContext: TicketsContext, action: TicketAction): TicketsContext {
  switch (action.type) {
    case TicketActionType.SUBMITTED_TICKET: {
      const submittedTicketAction = action as SubmittedTicketAction;
      const search = ticketsContext.search ? ticketsContext.search : '';
      const filteredTickets = ticketsContext.tickets.filter((ticket) => {
        return (
          getUserEmailFromAnswers(ticket.actionDetails.answers).toLowerCase().includes(search.toLowerCase()) ||
          getUserNameFromAnswers(ticket.actionDetails.answers).toLowerCase().includes(search.toLowerCase()) ||
          ticketsContext.selectedTicket?.ticketId === ticket.ticketId
        );
      });
      const currentTicketIndex = filteredTickets.findIndex(
        (ticket) => ticket.ticketId === submittedTicketAction.ticket.ticketId
      );

      const nextTicketIndex =
        currentTicketIndex === filteredTickets.length - 1 ? currentTicketIndex - 1 : currentTicketIndex + 1;

      const nextTicket = filteredTickets[nextTicketIndex];

      const updatedTickets =
        currentTicketIndex === 0
          ? ticketsContext.tickets
          : ticketsContext.tickets.filter((ticket: ActionPortalTicket) => {
              return ticket.ticketId !== submittedTicketAction.ticket.ticketId;
            });

      if (nextTicket?.ticketId) {
        //Mark selected ticket as read, fire and forget
        markAsRead(nextTicket.ticketId, { token: ticketsContext.token }).catch((error) => {
          Sentry.captureException('Failed to mark ticket as read', error);
        });
      }

      return {
        ...ticketsContext,
        tickets: updatedTickets,
        selectedTicket: nextTicket,
      };
    }
    case TicketActionType.SELECT_TICKET: {
      const selectTicketAction = action as SelectTicketAction;
      const selectedTicket = ticketsContext.tickets.find((ticket) => ticket.ticketId === selectTicketAction.ticketId);
      //Mark selected ticket as read, fire and forget
      markAsRead(selectTicketAction.ticketId, { token: ticketsContext.token }).catch((error) => {
        Sentry.captureException('Failed to mark ticket as read', error);
      });
      return {
        ...ticketsContext,
        selectedTicket: selectedTicket,
      };
    }
    case TicketActionType.REFRESH: {
      const refreshAction = action as RefreshTicketsAction;
      return {
        ...ticketsContext,
        tickets: refreshAction.tickets,
      };
    }
    case TicketActionType.UPDATED: {
      const updatedAction = action as UpdatedAction;
      const updatedTickets = ticketsContext.tickets.map((ticket: ActionPortalTicket) => {
        if (ticket.ticketId === updatedAction.ticket.ticketId) {
          return updatedAction.ticket;
        } else {
          return ticket;
        }
      });

      return {
        ...ticketsContext,
        tickets: updatedTickets,
      };
    }
    case TicketActionType.UPDATE_SEARCH: {
      const updateSearchAction = action as UpdateSearchAction;
      return {
        ...ticketsContext,
        search: updateSearchAction.search,
      };
    }
  }
}

export function useTickets(): TicketsContext {
  return useContext(TicketsContext);
}

export function useTicketsDispatch() {
  return useContext(TicketsDispatchContext);
}
