import type { Theme } from '@mui/material';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { forwardRef, useImperativeHandle } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import type {
  ActionPortalTicketResult,
  ActionPortalTicketResultType,
} from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { ErrorText, FormContainer } from './CommonStyles';
import { FormTextField } from './FormTextField';

export interface ActionPortalTicketFormData extends ActionPortalTicketResult {
  cancelledOn: string;
  hasUpcomingPayments: 'Yes' | 'No';
  upcomingPaymentDate?: string;
  numberOfUpcomingPayments?: string;
  serviceAvailableUntil: string;
  messageToUser?: string;
  type: ActionPortalTicketResultType.ActionPortalTicketResultCancelled;
}

interface TicketFormCancelledProps {
  resultType: ActionPortalTicketResultType;
  onSubmitCallback: (resultContent: ActionPortalTicketResult) => void;
  ref?: React.Ref<unknown>;
}

export const TicketFormCancelled: React.FC<TicketFormCancelledProps> = forwardRef(
  ({ resultType, onSubmitCallback }, ref) => {
    const {
      control,
      register,
      formState: { errors },
      watch,
      handleSubmit,
      // Needed, else we get a red warning on 'register' props
      // eslint-disable-next-line
    } = useForm<FieldValues>({ shouldUnregister: true, defaultValues: { hasUpcomingPayments: 'No' } });
    const watchHasUpcomingPayments = watch('hasUpcomingPayments', 'No');

    const theme: Theme = useTheme();

    const onSubmit = (data: ActionPortalTicketFormData) => {
      onSubmitCallback({
        ...data,
        cancelledOn: data.cancelledOn ? new Date(data.cancelledOn).toISOString() : null,
        hasUpcomingPayments: data.hasUpcomingPayments === 'Yes',
        upcomingPaymentDate: data.upcomingPaymentDate ? new Date(data.upcomingPaymentDate).toISOString() : null,
        numberOfUpcomingPayments: data.numberOfUpcomingPayments ? parseInt(data.numberOfUpcomingPayments) : null,
        serviceAvailableUntil: data.serviceAvailableUntil ? new Date(data.serviceAvailableUntil).toISOString() : null,
        type: resultType,
      } as ActionPortalTicketResult);
    };

    // Expose the onSubmit method through the ref
    useImperativeHandle(ref, () => handleSubmit(onSubmit));

    return (
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FormTextField
              control={control}
              errors={errors}
              register={register}
              propName="cancelledOn"
              propLabel="Cancelled on"
              fieldType="date"
              isRequired
              data-testid={'ticket-form-cancelled-on'}
            />
          </div>
          <FormControl error={Boolean(errors.hasUpcomingPayments)}>
            <FormLabel id="cancelled-form">
              <Typography variant="body2">Any upcoming payments?</Typography>
            </FormLabel>
            <Controller
              name="hasUpcomingPayments"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <RadioGroup row {...field} aria-labelledby="cancelled-form">
                  <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'No'} control={<Radio />} label="No" />
                </RadioGroup>
              )}
            />
            <ErrorText theme={theme}>
              {errors.hasUpcomingPayments?.type === 'required' && errors.hasUpcomingPayments
                ? 'This field is required'
                : ''}
            </ErrorText>
          </FormControl>
          {watchHasUpcomingPayments === 'Yes' && (
            <div>
              <FormTextField
                control={control}
                errors={errors}
                register={register}
                propName="numberOfUpcomingPayments"
                propLabel="Number of payments remaining"
                fieldType="number"
                data-testid={'ticket-form-number-upcoming-payments'}
                isRequired
              />
              <FormTextField
                control={control}
                errors={errors}
                register={register}
                propName="upcomingPaymentDate"
                propLabel="Next upcoming payment date"
                fieldType="date"
                data-testid={'ticket-form-upcoming-payment-date'}
                isRequired
              />
            </div>
          )}
          <div>
            <FormTextField
              control={control}
              errors={errors}
              register={register}
              propName="serviceAvailableUntil"
              propLabel="User can access service until"
              fieldType="date"
              data-testid={'ticket-form-cancelled-access-until'}
            />
          </div>
          <input type="submit" hidden />
        </form>
      </FormContainer>
    );
  }
);

TicketFormCancelled.displayName = 'TicketFormCancelled';
