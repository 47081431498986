import { Chip } from "@mui/material";
import {Age} from "../../core/types/tech/minna/core/modules/tickets/api/models/index";

interface AgeChipProps {
  age: Age;
  small?: boolean;
}

export const AgeChip: React.FC<AgeChipProps> = ({
  age: age,
  small,
}) => {
  let color: "success" | "error" | "warning" = "success";
  if (age.days > 5) {
    color = "error";
  } else if (age.days >= 3 && age.days <= 5) {
    color = "warning";
  }

  const text = age.days > 0 ? (age.hours > 0 ? `${age.days}d ${age.hours}h` : `${age.days}d`) : `${age.hours}h`;
  const size = small ? "small" : "medium";

  return (
    <Chip
      label={text}
      color={color}
      size={size}
      data-testid={`age-chip-${color}`}
      component={"span"}
      sx={{marginLeft: '12px'}}
    ></Chip>
  );
};
