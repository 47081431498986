import type { Currency } from '@minna-technologies/core-types/tech/minna/models';
import { useFormatter } from 'next-intl';

export function useLocalizedCostWithCurrency(amountInSmallesUnit?: number, currency?: Currency) {
  const format = useFormatter();

  if (!amountInSmallesUnit || !currency) {
    return '';
  }

  // As long as we don't support JPY, KWD, BHD, and OMR it is safe to divide amount by 100
  const amountInCurrencyUnit = amountInSmallesUnit / 100;

  return format.number(amountInCurrencyUnit, {
    style: 'currency',
    currency,
  });
}
