import type { Answer } from '@minna-technologies/core-types/tech/minna/core/api/integration/actions/v3/models';
import type { FULL_NAME } from '@minna-technologies/core-types/tech/minna/core/api/integration/actions/v3/models/Answer/index';

export function getUserEmailFromAnswers(answers: Answer[]): string {
  const merchantEmail = answers.find((answer) => (answer as any).questionId === 'que_merchantemail');
  const emailQuestion =
    merchantEmail && (merchantEmail as any)?.answer?.length > 0
      ? merchantEmail
      : answers.find((answer) => (answer as any).questionId === 'que_contactemail');
  return (emailQuestion as any)?.answer ?? '';
}

export function getUserNameFromAnswers(answers: Answer[]): string {
  const fullNameAnswer = answers.find((answer) => (answer as any).questionId === 'que_fullname');
  return fullNameAnswer
    ? `${(fullNameAnswer as FULL_NAME).answer.firstName} ${(fullNameAnswer as FULL_NAME).answer.lastName}`
    : '';
}

// Note, this is duplicated in apps/admin/app/tickets/ViewComponents/ticket-information-helper.ts and in Core: QuestionAnswerExtractionServiceImpl.scala
export function getOriginalAndAlternateAnswers(answers: Answer[]): { original: Answer[]; alternate: Answer[] } {
  const [original, alternate] = answers.reduce(
    ([original, alternate]: [Answer[], Answer[]], value: Answer) => {
      // @ts-ignore - There is an apigen bug/weirdness with how the answer model is modelled vs how it actually looks.
      if (original.some((variable) => variable.questionId === value.questionId)) {
        // The question has already been added, so we should add the current value to the alternate list
        return [original, [...alternate, value]];
      } else {
        // The question has not yet been added to the list, so we should add the current value to the original list
        return [[...original, value], alternate];
      }
    },
    [[], []]
  );

  return { original, alternate };
}
