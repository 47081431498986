import React, { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import type {
  ActionPortalTicketResult,
  ActionPortalTicketResultFixedContract,
  ActionPortalTicketResultType,
} from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { FormContainer } from './CommonStyles';
import { FormTextField } from './FormTextField';

interface TicketFormProps {
  resultType: ActionPortalTicketResultType;
  onSubmitCallback: (resultContent: ActionPortalTicketResult) => void;
  ref?: React.Ref<unknown>;
}

export const TicketFormFixedContract: React.FC<TicketFormProps> = forwardRef(
  ({ resultType, onSubmitCallback }, ref) => {
    const {
      register,
      control,
      formState: { errors },
      handleSubmit,
    } = useForm();
    const onSubmit = (data: ActionPortalTicketResultFixedContract) => {
      onSubmitCallback({
        ...data,
        contractEndsAt: data.contractEndsAt ? new Date(data.contractEndsAt).toISOString() : null,
        type: resultType,
      } as ActionPortalTicketResult);
    };

    // Expose the onSubmit method through the ref
    useImperativeHandle(ref, () => handleSubmit(onSubmit));
    return (
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FormTextField
              control={control}
              errors={errors}
              register={register}
              propName="contractEndsAt"
              propLabel="Contract ends at"
              fieldType="date"
              isRequired
            />
          </div>
          <input type="submit" hidden />
        </form>
      </FormContainer>
    );
  }
);

TicketFormFixedContract.displayName = 'TicketFormFixedContract';
