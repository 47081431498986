import styled from '@emotion/styled';
import type { Theme } from '@mui/material';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Paper, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslations } from 'next-intl';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type {
  ActionPortalTicket,
  ActionPortalTicketResult,
  ActionPortalTicketResultType,
  ActionPortalTicketResultUserDetailsNotMatching,
} from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { stringifyAnswer } from '../../utils/stringify-answer';
import { TransactionAnswerFields } from '../AnswerComponents/TransactionAnswer';
import { ErrorText, FormContainer } from './CommonStyles';

interface TicketFormProps {
  ticket: ActionPortalTicket;
  resultType: ActionPortalTicketResultType;
  onSubmitCallback: (resultContent: ActionPortalTicketResult) => void;
  ref?: React.Ref<unknown>;
}

const StyledPaper = styled(Paper)`
  max-height: 140px;
  overflow: auto;
  padding: 5px;
`;

const StyledFormGroup = styled(FormGroup)`
  width: 100%;
`;

export const TicketFormUserDetailsNotMatching: React.FC<TicketFormProps> = forwardRef(
  ({ ticket, resultType, onSubmitCallback }, ref) => {
    const {
      control,
      formState: { errors },
      handleSubmit,
    } = useForm({ shouldUnregister: true });
    const localise = useTranslations('TicketDetails');
    const theme: Theme = useTheme();

    const checkboxLabel = (answer) => {
      return answer.questionId === 'que_mostrecenttransaction'
        ? `${localise(answer.questionId)}:`
        : `${localise(answer.questionId)}: ${stringifyAnswer(answer)}`;
    };
    const transactionFields = (answer) => {
      return answer.questionId === 'que_mostrecenttransaction' ? (
        <TransactionAnswerFields answerObject={answer.answer} allowCopy={false} />
      ) : (
        <></>
      );
    };

    const onSubmit = (data: ActionPortalTicketResultUserDetailsNotMatching) => {
      // The data.answers contains the index of the question id, exchange it for the actual value.
      const answers = data.answers.map((answer) => {
        return ticket.actionDetails.answers[answer].questionId;
      });
      const updatedData: ActionPortalTicketResultUserDetailsNotMatching = { ...data, answers };
      onSubmitCallback({ ...updatedData, type: resultType } as ActionPortalTicketResult);
    };

    // Expose the onSubmit method through the ref
    useImperativeHandle(ref, () => handleSubmit(onSubmit));

    return (
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth error={Boolean(errors?.answers)}>
            <FormLabel id="cancelled-form">
              <Typography variant="body2">What&apos;s wrong?</Typography>
            </FormLabel>
            <StyledPaper>
              <Controller
                name="answers"
                control={control}
                defaultValue={[]}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    {ticket.actionDetails.answers.map((answer: any, index) => {
                      const questionId = answer.questionId;

                      return (
                        <>
                          <StyledFormGroup>
                            <FormControlLabel
                              key={index}
                              label={checkboxLabel(answer)}
                              control={
                                <Checkbox
                                  {...field}
                                  checked={Array.isArray(field.value) ? field.value.includes(index) : false}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    let values = Array.isArray(field.value) ? [...field.value] : [];
                                    if (isChecked) {
                                      values.push(index);
                                    } else {
                                      values = values.filter((val) => val !== index);
                                    }
                                    field.onChange(values);
                                  }}
                                  value={questionId}
                                />
                              }
                            />
                            {transactionFields(answer)}
                          </StyledFormGroup>
                        </>
                      );
                    })}
                  </div>
                )}
              />
            </StyledPaper>
            <ErrorText theme={theme}>
              {errors.answers?.type === 'required' && !!errors.answers ? 'This field is required' : ''}
            </ErrorText>
          </FormControl>
          <input type="submit" hidden />
        </form>
      </FormContainer>
    );
  }
);

TicketFormUserDetailsNotMatching.displayName = 'TicketFormUserDetailsNotMatching';
