import type { Theme } from '@mui/material';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type {
  ActionPortalTicketResult,
  ActionPortalTicketResultPayingViaThirdParty,
  ActionPortalTicketResultType,
} from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { ThirdPartyPaymentMethod } from '@minna-technologies/core-types/tech/minna/core/modules/actionportal/api/models';
import { ErrorText, FormContainer } from './CommonStyles';
import { FormTextField } from './FormTextField';

interface TicketFormProps {
  resultType: ActionPortalTicketResultType;
  onSubmitCallback: (resultContent: ActionPortalTicketResult) => void;
  ref?: React.Ref<unknown>;
}

export const TicketFormPayingViaThirdParty: React.FC<TicketFormProps> = forwardRef(
  ({ resultType, onSubmitCallback }, ref) => {
    const {
      control,
      register,
      formState: { errors },
      watch,
      handleSubmit,
    } = useForm({ shouldUnregister: true });

    const watchService = watch('service');
    const theme: Theme = useTheme();

    const onSubmit = (data: ActionPortalTicketResultPayingViaThirdParty) => {
      onSubmitCallback({
        ...data,
        type: resultType,
      } as ActionPortalTicketResult);
    };

    // Expose the onSubmit method through the ref
    useImperativeHandle(ref, () => handleSubmit(onSubmit));

    return (
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl error={Boolean(errors?.service)}>
            <FormLabel id="paying-via-third-party-label">
              <Typography variant="body2">Which third party?</Typography>
            </FormLabel>
            <Controller
              name="service"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value={ThirdPartyPaymentMethod.APPLE}
                    control={<Radio />}
                    label="Apple"
                    key={ThirdPartyPaymentMethod.APPLE}
                  />
                  <FormControlLabel
                    value={ThirdPartyPaymentMethod.GOOGLE}
                    control={<Radio />}
                    label="Google"
                    key={ThirdPartyPaymentMethod.GOOGLE}
                  />
                  <FormControlLabel
                    value={ThirdPartyPaymentMethod.OTHER}
                    control={<Radio />}
                    label="Other"
                    key={ThirdPartyPaymentMethod.OTHER}
                  />
                </RadioGroup>
              )}
            />
            <ErrorText theme={theme}>
              {errors.service?.type === 'required' && !!errors.service ? 'This field is required' : ''}
            </ErrorText>
          </FormControl>
          <div>
            {watchService === ThirdPartyPaymentMethod.OTHER && (
              <FormTextField
                control={control}
                errors={errors}
                register={register}
                propName="other"
                propLabel="Other"
                isRequired
              />
            )}
          </div>
          <input type="submit" hidden />
        </form>
      </FormContainer>
    );
  }
);

TicketFormPayingViaThirdParty.displayName = 'TicketFormPayingViaThirdParty';
