import styled from '@emotion/styled';
import { Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { InternalServerError } from './ErrorComponents/InternalServerError';
import { TicketAlreadyHandled } from './ErrorComponents/TicketAlreadyHandled';
import { TicketExpired } from './ErrorComponents/TicketExpired';
import { Footer } from './Footer';
import { TicketDetails } from './TicketDetails';
import { TicketForm } from './TicketForm';
import { TicketActionType, useTickets, useTicketsDispatch } from './TicketsProvider';
import Box from '@mui/material/Box';

interface TicketViewProps {
  token: string;
  onSubmit: (resultState: Responses) => void;
}

const View = styled.div`
  margin-left: 1px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: space-between;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
`;

export enum Responses {
  TICKET_VIEW = 0,
  SUCCESS = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 403,
  INTERNAL_SERVER_ERROR = 500,
}

export const TicketView = ({ token, onSubmit }: TicketViewProps) => {
  const ticketsContext = useTickets();
  const ticketsDispatch = useTicketsDispatch();
  const ticket = ticketsContext.selectedTicket;
  const initialResultState = ticket?.open ? Responses.TICKET_VIEW : Responses.SUCCESS;
  const [resultState, setResultState] = useState(initialResultState);

  useEffect(() => {
    setResultState(initialResultState);
  }, [initialResultState, ticket]);

  const onSubmitCallback = (resultState: Responses) => {
    if (resultState === Responses.SUCCESS) {
      ticketsDispatch({
        type: TicketActionType.UPDATED,
        ticket: { ...ticket, open: false },
      });
      ticketsDispatch({
        type: TicketActionType.SUBMITTED_TICKET,
        ticket: ticket,
      });
    }
    setResultState(resultState);
    onSubmit(resultState);
  };

  const ticketContent = () => {
    switch (resultState) {
      case Responses.SUCCESS:
        return <TicketAlreadyHandled />;
      case Responses.INTERNAL_SERVER_ERROR:
        return <InternalServerError />;
      case Responses.UNAUTHORIZED:
        return <TicketExpired />;
      default:
        return (
          <>
            <Typography variant="h4">
              Request to{' '}
              {ticket.actionDetails.actionType === 'RETENTION_OFFER'
                ? 'Apply Retention Offer'
                : ticket.actionDetails.actionType}
            </Typography>
            <Divider sx={{ margin: '8px 0' }} />
            <Typography variant="body1">
              Please{' '}
              {ticket.actionDetails.actionType === 'RETENTION_OFFER' ? 'apply retention offer for this ' : 'cancel'}{' '}
              <Box component="span" fontWeight="fontWeightBold">
                {ticket.serviceDetails.merchantName}
              </Box>{' '}
              subscription for the following user
            </Typography>
            <Row>
              <TicketDetails ticket={ticket} />
              <TicketForm token={token} ticket={ticket} onSubmitCallback={onSubmitCallback} />
            </Row>
          </>
        );
    }
  };

  return (
    <View>
      <div style={{ flex: 1 }}>{ticketContent()}</div>
      <Footer />
    </View>
  );
};
