import styled from '@emotion/styled';
import type { Theme } from '@mui/material';
import { FormHelperText, TextField } from '@mui/material';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-right: 16px;
`;
export const StyledTextField = styled(TextField)`
  margin-top: 16px;
`;
export const ErrorText = styled(FormHelperText)`
  color: ${(props: { theme: Theme }) => props.theme.palette.error.main};
`;
